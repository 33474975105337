import React from "react";
import { Link } from "gatsby";
import SEO from "../../components/Seo";
import Layout from "../../components/Layout";
import { hourlyRate } from "../../components/Helpers";
import MainContent from "../../components/MainContent";
import Breadcrumb from "../../components/Breadcrumb";
import Img from "../../components/Img.js";
import H from "../../components/Headline";
import ProfileCard from "../../components/ProfileCard";
import ContactForm from "../../components/ContactForm";
import ImgScreenshot from "../../components/ImgScreenshot.js";
import { useAlternateLangs } from "../../components/Hreflangs";

// Hreflang data
const alternateLangs = useAlternateLangs(
  // replace with pages x-default
  "/en/google-tag-manager-consultant"
);


const breadCrumbLevels = {
  Koti: "/",
  "Tietoja minusta": "/fi/tietoa",
  "Google Tag Manager Konsultti": "/fi/google-tag-manager-konsultti"
};



const GoogleTagManagerConsultant = props => (
  <Layout location={props.location} alternateLangs={alternateLangs}>

    <React.Fragment>
      <SEO
        title="Freelance Google Tag Manager Konsultti: M. Kupperschmidt"
        description="Matthias Kupperschmidt on freelance-konsultti ja kehittäjä Google Tag Managerille. Lue hänen taustastaan, palveluistaan ja pyydä tarjous."
        lang="fi"
        canonical="/fi/google-tag-manager-konsultti"
        image="tag-management-consultant-hero"
        alternateLangs={alternateLangs}
      />
      <MainContent article>
        <Img
          src="tag-manager-consultant/matthias-kupperschmidt-presenting-at-founders-house-oct-2019.jpg"
          alt="Google Tag Manager freelance-konsultti Matthias Kupperschmidt esittelemässä Founders Housessa, lokakuu 2019"
        />
        <Breadcrumb breadCrumbLevels={breadCrumbLevels} />
        <H as="h1">Google Tag Manager Konsultti</H>
        <ProfileCard
          tags={["Tracking Setup", "Retargeting", "Ecommerce Tracking", "Google Analytics", "Custom Event Tracking", "JavaScript", "React", "Python", "EN, DE, DA"]}
          profession="Google Tag Manager Konsultti"
          rate={`${hourlyRate}€/hr`}
          location="etänä Kööpenhaminasta, DK"
          cta="Ota yhteyttä"
          alt="GTM-asiantuntija"
        />
        <p>Olen Google Tag Manager -konsultti ja suunnittelen sekä toteutan <Link to="/fi/tagien-hallinta">taginhallintajärjestelmiä</Link> asiakkailleni.</p>
        <p>
          Taginhallintajärjestelmillä voit toteuttaa useita kolmannen osapuolen tageja (esim. Google Analytics, Facebook
          pikseli, Google Ads) verkkosivustollesi nopeasti ja helposti.
        </p>
        <p className="baseline">
          Taginhallintajärjestelmistä on tullut erittäin suosittu markkinointiteknologia viime vuosina. Itse asiassa maailmanlaajuisen taginhallintajärjestelmämarkkinoiden odotetaan jatkavan <a href="https://www.businesswire.com/news/home/20191209005363/en/Global-Tag-Management-System-Market-Analysis-Report" target="">kasvua 5,7% vuosikasvulla vuoteen 2024 asti</a>.
        </p>

        <p className="baseline">
          Google Tag Manager (GTM) on käytetyin taginhallintaratkaisu, jonka <a href="https://trends.builtwith.com/analytics/tag-management/traffic/Entire-Internet" target="_blank">markkinaosuus on 94% taginhallintamarkkinoista</a> ja se on <b>ilmainen</b> vakioversiossaan.
        </p>
        <p>
          GTM:n avulla otan käyttöön seurantasettauksia, jotka on integroitu kolmannen osapuolen tageihin ja <b>skaalautuvat useille verkkosivustoille</b> ja kielille.
        </p>
        <p>
          Sen avulla voit käyttää käyttäjävuorovaikutuksia Google Analytics -tililtäsi luodaksesi käyttäjäsegmenttejä markkinointiponnisteluihisi, kuten mainoskampanjoihin Facebook Adsissa, Doubleclickissä tai LinkedInissä.
        </p>
        <br />
        <br />
        <H as="h6" style={{ "textAlign": "center" }}>Kenelle olen työskennellyt</H>
        <ImgScreenshot
          src="about/clients_700px.png"
          alt="asiakkaat, joiden kanssa olen työskennellyt"
          className="article-img"
        />
        <br />
        <br />
        <br />


        <H as="h2" style={{ "textAlign": "center" }}>Pyydä tarjous</H>
        <ContactForm showHeadline={false} formName="ga konsultti (FI) - yhteydenottolomake" />

        <H as="h2">Palvelut</H>
        <p>
          Freelance Google Tag Manager -konsulttina tarjoan samat palvelut (tai enemmän) kuin tyypillinen digitaalinen
          markkinointitoimisto. Säästät aikaa ja budjettia, koska viestintä on suoraan Google Tag Manager -asiantuntijan kanssa. Ei
          projektinhallinnan ja hallinnon ylimääräisiä kustannuksia.
        </p>
        <p>Tässä lyhyt lista <b>Google Tag Manager -konsultointipalveluistani</b>:</p>
        <ul>
          <li>Google Tag Managerin asennus</li>
          <li>evästeilmoitukset</li>
          <li><Link to="/fi/tapahtuman-seuranta">tapahtumaseuranta</Link></li>
          <li>
            <Link to="/fi/google-analytics-asennus">Google Analyticsin käyttöönotto</Link>
          </li>
          <li>kampanjoiden koordinointi median ja verkkotoimistojen kanssa</li>
          <li>laajennettu eCommerce-seuranta</li>
          <li>
            <Link to="/fi/data-kerros">datalayerin toteutus</Link>
          </li>
          <li>dataintegraatio tagien välillä</li>
          <li>uudelleenkohdentaminen esim. Facebookilla, Google Adsilla, Twitterillä, LinkedInillä</li>
          <li>tavoitteiden konversioseuranta</li>
          <li>affiliate-seuranta</li>
          <li>kampanjaseuranta</li>
          <li>videoseuranta</li>
          <li>datan tuonti ja vienti</li>
          <li>virheiden korjaus ja vianmääritys</li>
          <li>mukautetut tag-ratkaisut JavaScriptillä</li>
        </ul>
        <p>
          Mikä tahansa tag-ratkaisu voidaan ottaa käyttöön ja muuttaa nopeasti <b>GTM-kontin</b> tai minkä tahansa muun taginhallintaratkaisun kautta. GTM on kehittynyt standardiksi tiedon, markkinointitagien, analytiikan ja mukautettujen tag-toteutusten hallintaan, lähinnä siksi, että GTM-tili on ilmainen ja toimii luotettavasti.
        </p>
        <p>
          Julkaisunsa jälkeen markkinoijat voivat vihdoin toteuttaa ja säätää digitaalista kampanjastrategiaansa erittäin joustavasti, sillä lähdekoodiin ei tarvitse tehdä muutoksia.
        </p>
        <H as="h2">Mikä on Google Tag Manager?</H>
        <p>
          Google Tag Manager on työkalu tagien toteuttamiseen ja konfigurointiin verkkosivustolla. Voit määritellä säännöt jokaisen koodinpätkän suorittamiselle ja määritellä tarkasti, mihin tietoihin sillä on pääsy.
        </p>
        <p>
          Kaikki tehdään käyttäjäystävällisestä Google Tag Manager -käyttöliittymästä, joka tarjoaa nopean ja sujuvan kehittäjäkokemuksen. Uusien tagien toteuttaminen graafisen käyttöliittymän (GUI) kautta on itsessään tervetullut uusi kokemus, koska se vaatii vähemmän keskittymistä ja säästää toteutusaikaa.
        </p>
        <p>
          <b>Google Tag Manager on pohjimmiltaan <Link to="/fi/wiki-analytics/säilö-tunniste">konttitagi</Link>, jonka kautta voit toteuttaa kaikki muut markkinointitagit verkkosivustollasi</b>. Vain Google Tag Manager -koodinpätkä menee lähdekoodiin, ja loput koodinpätkät voidaan toteuttaa ja konfiguroida laajasti Google Tag Manager -tilistä.
        </p>
        <p>Voit jopa testata uutta tagia esikatselutilassa varmistaaksesi, että kaikki toimii halutulla tavalla ennen kuin lähetät oikeaa dataa analytiikkatyökaluusi.</p>
        <p className="baseline">
          Joustavuutensa ansiosta siitä tuli erittäin suosittu online-markkinoinnin ja tiedonhallinnan väline, erityisesti Googlen tuotteiden kanssa. Se on niin suosittu, että <a href="https://w3techs.com/technologies/overview/tag_manager" target="_blank">sitä käyttää 30,4% verkkosivustoista</a>, <a href="https://trends.builtwith.com/widgets/Google-Tag-Manager" target="_blank">mikä tarkoittaa 4,9 miljoonaa elävää verkkosivustoa, jotka käyttävät GTM:ää</a>. Tyypillisiä käyttötapauksia ovat Google Analytics -tagit, jotka jakavat dataa mainospalvelujen kanssa (esim. Facebook, Google Ads).
        </p>
        <p>
          Lue tarkempi{" "}
          selitys <Link to="/fi/mikä-on-google-tag-manager">siitä, mikä Google Tag Manager on</Link> ja katso joitakin kuvakaappauksia Google Tag Manager -koodista ja sen käyttöliittymästä, jos haluat tietää enemmän.
        </p>
        <p>
          Jos haluat oppia, miten käyttää Google Tag Manageria Google Analyticsin kanssa, lue minun{" "}
          opas <Link to="/fi/google-analytics-asennus">Google Analyticsin käyttöönotosta GTM:llä</Link> ja myös <Link to="/fi/tapahtuman-seuranta">tapahtumaseurannan asettamisesta GTM:llä</Link>.
        </p>

        <H as="h2">Mitkä ovat Google Tag Managerin hyödyt?</H>
        <p>
          Google Tag Managerin pääasiallinen hyöty on monien tagien nopea ja joustava käyttöönotto ja skaalautuva datanhallinta niiden kesken.
        </p>
        <p>
          Nämä verrattain pienet toteutusmuutokset vievät aikaa ja kertyvät asiakkaille, jotka ajavat monia digitaalisia kampanjoita. Hyvät ohjelmoijat ovat kalliita ja toimistoilla on rajalliset resurssit. Siksi on järkevää ulkoistaa tagien toteutustehtävät työkalulle, jossa on graafinen käyttöliittymä (GUI) ja nopeampi käyttöönottoaika.
        </p>
        <p>
          Lue lisää <Link to="/fi/google-tag-manager-hyodyt">syvällinen lista Google Tag Managerin hyödyistä</Link>, jos haluat tietää lisää.
        </p>

        <H as="h2">Miksi tarvitset Google Tag Manager -konsultointia?</H>
        <p>
          GTM-konsultti suunnittelee Google Analytics -toteutuksen ja varmistaa, että muut markkinointipalvelut saavat oikeat tiedot esimerkiksi kampanjakäynneistä ja konversioista segmenttien optimointia varten.
        </p>
        <p>
          Jos ajat monia kampanjoita yksittäisillä laskeutumissivuilla, GTM-asiantuntija lisää konversioseurannan analytiikkaraportteihin nopeasti.
        </p>
        <p>
          Seuraamalla tapahtumia laskeutumissivullasi voit tarkistaa kampanjoidesi jatkuvan suorituskyvyn. Jos kampanja ei suoriudu odotetusti, huomaat sen varhain ja voit säätää uudelleen. Tämä palaute-sykli jokaiselle kampanjalle tuo mukanaan oppeja tulevien kampanjoiden optimointiin.
        </p>

        <p>
          Google Tag Manager -asiantuntija toimii myös kehittäjänä markkinointitiimissäsi ja auttaa tagien,
          JavaScript-tehtävien ja mukautettujen ratkaisujen kanssa sivustollasi.
        </p>
        <p>
          GTM-konsultointi on yhä tärkeämpää asiakkaille, jotka luottavat muihin digitaalisiin markkinointipalveluihin
          liiketoiminnassaan. Se maksimoi jokaisen sivun katselun arvon korkealaatuisella sitouttamistiedolla SEO:sta, sähköpostista ja
          uudelleenkohdennuskampanjoista auttaen optimoimaan mainoskulut.
        </p>

        <H as="h2">Kuinka paljon GTM-konsultointi maksaa?</H>
        <p className="baseline">
          Freelance-hintani on <b>{hourlyRate}€</b> tunnissa netto. Jos suunnittelen Google Analytics -toteutuksen
          asiakkaalle, arvioin työn laajuuden ja lähetän ehdotuksen, jossa kuvataan kaikki toimitukset. Laajuus
          riippuu sivuston koosta ja seurattavien KPI:iden määrästä.
        </p>
        <p className="baseline">
          Sivuston taustalla oleva teknologia vaikuttaa myös toteutuksen helppouteen. Google Analytics -asetukset
          tapahtumaseurannalla ja kolmannen osapuolen dataintegraatiolla maksavat välillä <b>1800€</b> ja <b>6000€</b> netto.
        </p>
        <p>
          Jos tarvitset jatkuvaa Google Tag Manager -apua suuremmalle sivustolle, voidaan sopia kuukausittainen kiinteä budjetti
          odotetun työmäärän mukaan. Kuukausittaisissa kiinteissä budjeteissa tulisi huomioida vähintään 10 tuntia kuukaudessa.
        </p>
        <p className="baseline">
          Pienemmät tehtävät veloitetaan kiinteällä maksulla välillä <b>100€</b> ja <b>300€</b>.
        </p>
        <H as="h2">Profiilini</H>
        <p>
          Rakensin ensimmäisen verkkosivustoni 14-vuotiaana vaihtaakseni elokuvia luokkatoverieni kanssa. 18-vuotiaana
          aloitin verkkokaupan, joka myi pöytäjalkapallotarvikkeita nimeltä "Kickerkult". Kun perehdyin enemmän analytiikkatyökaluihin, SEO:hon
          ja lähetin ensimmäiset uutiskirjeeni, kiinnostuin verkosta. Siitä lähtien halusin keskittyä urallani internetiin.
        </p>
        <p>
          Olen työskennellyt digitaalisissa toimistoissa vuodesta 2011 ja keskittynyt pelkästään SEO:hon ja analytiikkaan vuodesta 2015. Olen rakentanut taginhallintajärjestelmiä GTM:llä verkkokauppasivustoille, supermarket-ketjuille, autovalmistajille, ravintolaketjuille,
          vakuutusyhtiöille ja monille muille aloille. Asiakkaani ovat yleensä suuryrityksiä, suuria yhtiöitä tai heillä on sisäisesti samankaltaiset organisaatiot ja toimintatavat.
        </p>
        <p>
          Olen työskennellyt itsenäisenä Google Tag Manager -freelancekonsulttina vuodesta 2018. Teen myös hakukoneoptimointia
          ja <Link to="/fi/analytiikka-konsultointi">analytiikkakonsultointia</Link>. Kaikki yhdestä kädestä.
        </p>
        <p>
          Persoonallisuuteni on analyyttinen, rationaalinen ja pragmaattinen. Pidän analyysista löytääkseni oikean ratkaisun, mutta en halua asioiden olevan monimutkaisia. Siksi päätös siirtyä hektisestä toimistoympäristöstä työskentelemään itselleni GTM-freelancerina oli looginen seuraus.
        </p>
        <p>
          Pitääkseni tietoni ajan tasalla taginhallinnan, analytiikan ja SEO:n suhteen, ylläpidän muutamia verkkosivustoja ja käyn konferensseissa. Olen myös mukana järjestämässä Kööpenhaminan SEO-Meetupia.
        </p>

        <p>
          Voit lukea lisää taustastani <a href="https://www.linkedin.com/in/matthiaskupperschmidt">Linkedinistä</a> tai löydät lisää tietoa <Link to="/fi/tietoa">tietoja minusta -sivulta</Link>.
        </p>
        <H as="h2">Etsitkö freelance Google Tag Manager -asiantuntijaa?</H>
        <p>
          Ota yhteyttä, jos etsit apua Google Tag Manager -toteutuksessa. Voin konsultoida, mitä tapahtumia seurata ja mitä indikaattoreita
          tarkastella konversioiden optimoimiseksi.
        </p>
        <p>
          Pyydä tarjous <Link to="/fi/yhteydenotto">yhteydenottosivun</Link> kautta ja vastaan ehdotuksella.
        </p>
        {/* <RelatedContent /> */}
      </MainContent>
    </React.Fragment>

  </Layout>
);

export default GoogleTagManagerConsultant;
